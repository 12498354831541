import React from "react";
import MainImage from "./MainImage";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import Figure from "./Figure";
import CTA from "./cta";
import InfoRows from "./InfoRows";

const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <React.Fragment></React.Fragment>;
};

const serializers = {
  types: {
    authorReference: AuthorReference,
    mainImage: ({ node }) => <MainImage mainImage={node} />,
    contentImage: Figure,
    infoRows: ({ node }) => <InfoRows {...node} />,
    ctaPlug: ({ node }) => <CTA {...node} />,
    videoEmbed: ({ node }) => <ReactPlayer className="mt-6 mb-6" url={node.url} controls />,
    instagram: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6" />;
    },
  },
  marks: {
    link: ({ mark: { mailto, tel, href, title = "" }, ...props }) => {
      href = mailto || tel || href;
      return (
        <a className="no-underline hover:underline" href={href} title={title}>
          {props.children}
        </a>
      );
    },
  },
};

export default serializers;

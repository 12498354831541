import React from "react";
import PropTypes from "prop-types";

import CTALink from "./CTALink";

const NavMenuGroupItem = (props) => {
  return (
    <li className="relative mr-2 font-semibold py-2 text-yellow-800 hover:text-yellow-600 uppercase group">
      <a className="" href={props.route} title={props.title}>
        <span className="firstlevel">{props.title}</span>
      </a>
      <ul
        className="lg:absolute top-0 lg:mt-10 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block overflow-scroll"
        style={{ maxHeight: 600 }}
      >
        <svg
          className="block fill-current text-white w-4 h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
        </svg>
        {props.items.map((item) => {
          if (item._type === "ctaGroup") {
            return (
              <li
                key={item._key}
                className="p-2 whitespace-no-wrap text-sm md:text-base text-yellow-800 hover:text-yellow-600 hover:bg-gray-100"
              >
                <CTALink {...item} />
                <ul className="flex flex-row flex-wrap mt-2">
                  {item.items.map((subItem) => {
                    return (
                      <li
                        className="p-2 text-xs md:text-md hover:text-yellow-700 text-yellow-900 leading-tight font-normal"
                        key={subItem._key}
                      >
                        <a href={subItem.route} title={subItem.title}>
                          {subItem.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
          return (
            <li
              key={item._key}
              className="p-2 whitespace-no-wrap text-sm md:text-base text-yellow-800 hover:text-yellow-600 hover:bg-gray-100"
            >
              <CTALink {...item} />
            </li>
          );
        })}
      </ul>
    </li>
  );
};

NavMenuGroupItem.propTypes = {};

export default NavMenuGroupItem;

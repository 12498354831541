import React from "react";
import PropTypes from "prop-types";
import CTALink from "./CTALink";

const NavMenuItem = (props) => {
  return (
    <li className="relative mr-2 font-semibold py-2 text-yellow-800 hover:text-yellow-600 uppercase group">
      <CTALink {...props} />
    </li>
  );
};

export default NavMenuItem;

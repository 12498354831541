import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

// https://ogp.me

function SEO({ description, lang, meta, keywords, title, image, url, bodyAttr, gradient }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description ||
          (data.site && data.site.description) ||
          (data.site && data.site.openGraph && data.site.openGraph.description) ||
          "";
        const siteTitle = (data.site && data.site.title) || "";
        const siteAuthor = (data.site && data.site.author && data.site.author.name) || "";
        const metaImage =
          image && image.asset
            ? imageUrlFor(buildImageObj(image)).width(1200).url()
            : data.site &&
              data.site.openGraph &&
              data.site.openGraph.image &&
              data.site.openGraph.image.asset
            ? imageUrlFor(buildImageObj(data.site.openGraph.image)).width(1200).url()
            : "";

        const pageTitle = title || siteTitle;

        return (
          <Helmet
            bodyAttributes={bodyAttr}
            htmlAttributes={{ lang }}
            title={pageTitle}
            titleTemplate={pageTitle === siteTitle ? siteTitle : '%s'}
            meta={[
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: title,
              },
              {
                property: "og:description",
                content: metaDescription,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:image",
                content: metaImage,
              },
              {
                property: "og:url",
                content: url,
              },
              {
                property: "og:locale",
                content: lang,
              },
              {
                name: "twitter:card",
                content: "summary",
              },
              {
                name: "twitter:creator",
                content: siteAuthor,
              },
              {
                name: "twitter:title",
                content: title,
              },
              {
                name: "twitter:description",
                content: metaDescription,
              },
              {
                name: "fb:app_id",
                content: "742368589893052",
              },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", "),
                    }
                  : []
              )
              .concat(meta)}
          >
            {gradient && gradient.from && gradient.to && (
              <style type="text/css">{`
              .gradient {
                background: linear-gradient(90deg, ${gradient.from} 0%, ${gradient.to} 100%);
              }
            `}</style>
            )}
            <link rel="icon" href="favicon.png" type="image/png" />
            <link rel="canonical" href={url} />
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5564300156788637"
     crossorigin="anonymous"></script>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-86847932-7"></script>
            <script>{`
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "UA-86847932-7");`}</script>
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      hotlineNumber
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

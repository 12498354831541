import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

const MainImage = ({ className, mainImage, width = 1200 }) => {
  const imgUrl =
    mainImage &&
    imageUrlFor(buildImageObj(mainImage)).width(width).fit("crop").auto("format").url();

  return imgUrl ? (
    <img className={className} src={imgUrl} alt={mainImage.alt || ""} />
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default MainImage;

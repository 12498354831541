import { Link, graphql, StaticQuery } from "gatsby";
import React from "react";
import PortableText from "./portableText";

const footerNavQuery = graphql`
  query FooterNavQuery {
    footerMenu: sanityNavigationMenu(title: { eq: "Footer Menu" }) {
      id
      _key
      title
      ...NavMenu
    }
  }
`;

const Footer = ({ siteTitle, author }) => (
  <footer className="bg-gray-200">
    <div className="bg-gray-200">
      <div className="container mx-auto px-2">
        <div className="w-full flex flex-col md:flex-row pt-8 pb-12">
          {author && (
            <div className="flex-1 mr-4">
              <p className="uppercase text-gray-600 md:mb-6">Liên hệ</p>
              <div className="">
                <PortableText blocks={author._rawBio} />
              </div>
            </div>
          )}
          <div className="flex-1 mr-4">
            <p className="uppercase text-gray-600 md:mb-6">Chính sách</p>
            <StaticQuery
              query={footerNavQuery}
              render={(data) => {
                const menuItems = data.footerMenu?.items || [];
                const socialItems = [
                  { _key: "twitter", title: "Twitter", route: "https://twitter.com/capsangjin" },
                  {
                    _key: "youtube",
                    title: "Youtube",
                    route: "https://www.youtube.com/channel/UCEv9Xa-9jBHm1rz5Lkj-oKw/about",
                  },
                  {
                    _key: "linkedin",
                    title: "LinkedIn",
                    route: "https://www.linkedin.com/in/dohoainhan/",
                  },
                  {
                    _key: "instagram",
                    title: "Instagram",
                    route: "https://www.instagram.com/capdiensaigon/",
                  },
                ];
                return (
                  <ul className="list-reset mb-6">
                    {menuItems.map(({ _key, title, route }) => {
                      return (
                        <li
                          key={_key}
                          className="mt-2 inline-block mr-2 md:block md:mr-0 cursor-pointer"
                        >
                          <Link
                            to={route}
                            className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                          >
                            {title}
                          </Link>
                        </li>
                      );
                    })}
                    {socialItems.map(({ _key, title, route }) => (
                      <li
                        key={_key}
                        className="mt-2 inline-block mr-2 md:inline-block md:mr-2 cursor-pointer"
                      >
                        <a
                          href={route}
                          className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                          rel="noreferrer noopener nofollow"
                          alt={title}
                        >
                          {title}
                        </a>
                      </li>
                    ))}
                  </ul>
                );
              }}
            />
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-600 md:mb-6">Fanpage</p>
            <div
              className="fb-page"
              data-href="https://www.facebook.com/capdiensaigon2020"
              data-tabs="timeline"
              data-width=""
              data-height="260"
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/capdiensaigon2020"
                className="fb-xfbml-parse-ignore"
              >
                <a
                  href="https://www.facebook.com/capdiensaigon2020"
                  alt="Fanpage Capdiensaigon"
                  rel="noreferrer noopener nofollow"
                >
                  Capdiensaigon.com
                </a>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gray-800">
      <div className="container mx-auto py-4">
        <p className="text-white">Copyright 2021 © capdiensaigon.com</p>
      </div>
    </div>
  </footer>
);

export default Footer;

import React from "react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

export default ({ node }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  const fluidProps = getFluidGatsbyImage(node.asset._id, { maxWidth: 675 }, clientConfig.sanity);
  return (
    <figure style={{ maxWidth: 675, margin: "1rem auto" }}>
      <Img fluid={fluidProps} alt={node.alt} />
      <figcaption className="text-center my-2 text-gray-600 text-sm">{node.caption}</figcaption>
    </figure>
  );
};
